import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/Home/Title"
import Testimonials from "../components/Home/Testimonials"
import About from "../components/Home/About"
import Services from "../components/Home/Services"
import Teams from "../components/Home/Teams"

const IndexPage = () => (
   <Layout>
      <SEO title="Home" />
      <Title />
      <Services />
      <Testimonials />
      <Teams />
      <About />
   </Layout>
)

export default IndexPage
