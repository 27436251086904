import React from "react"
import styled from "styled-components"

import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import ImageContainer from "../styles/ImageContainer"

const StyledCard = styled(Card)`
   width: 250px;
   /* width: ${props => props.theme.cardMaxWidth}; */
   margin: 25px;
   padding: 16px 16px 24px 16px;
   display: flex;
   flex-direction: column;
   align-content: center;
   justify-content: space-around;
`

const TeamCard = ({fileName, children}) => {
   const teamName = fileName.substr(0, fileName.lastIndexOf('.'))
   return (
      <StyledCard>
         <ImageContainer>
            {children}
         </ImageContainer>
         <div>
            <Typography gutterBottom variant="h5" component="h2" align='center'>
               {teamName}
            </Typography>
         </div>
      </StyledCard>
   )
}

export default TeamCard;