import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import SectionHeader from '../SectionHeader'
import SectionContainer from '../styles/HomeSectionContainer'

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import bgTexture from '../../images/concrete-wall-2.png';
import { useStaticQuery, graphql } from 'gatsby';

const BGContainer = styled.div`
  background-color: ${props => props.theme.grey};
  background-image: url(${bgTexture});
`;

const StyledCard = styled(Card)`
   background-color: ${props => props.theme.offWhite};
   max-width: 780px;
   margin: 25px;
`;

const ImageContainer = styled.div`
  margin-bottom: 0.55em;
`;

const About = () => {
  const data = useStaticQuery(graphql`
      query {
         headerImage: file(
            relativePath: { eq: "dave_lift_1.jpg" }
         ){
            childCloudinaryAsset {
              fluid{
                ...CloudinaryAssetFluid
              }
            }
         }
      }
   `)
   return (
      <BGContainer>
         <SectionContainer>
            <SectionHeader title='About Me' color='light'/>
            <div style={{ display: 'flex', justifyContent:'center'}}>
               <StyledCard>
                  <CardContent>
                  <ImageContainer>
                    <Img fluid={data.headerImage.childCloudinaryAsset.fluid} />
                  </ImageContainer>
                  <Typography variant='body1' color='textPrimary' component='p'>
                     Grym means power in the Welsh language. My name is David Thomas, as a Welsh man living in Canada I thought it appropriate to name the company something tied to my roots. I began working with athletes in 2011 as a strength coach and since then have gained a degree in Sport & Exercise science and competed internationally in powerlifting on several occasions. Working with childrens teams through to serving Army members and old age pensioners.
                  </Typography>
                  </CardContent>
               </StyledCard>
            </div>
         </SectionContainer>
      </BGContainer>
   )
}

export default About;
