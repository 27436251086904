import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Title = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "title4.jpeg" }) {
        childCloudinaryAsset {
          fluid (maxWidth: 1280) {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `)
   return (
    <div>
      <Img sizes={data.file.childCloudinaryAsset.fluid} />
    </div>
   )
}

export default Title
