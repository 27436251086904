import styled from "styled-components";
import Button from "@material-ui/core/Button";

const PrimaryButton = styled(Button)`
  background-color: ${props => props.theme.lightgreen} !important;
  color: ${props => props.theme.black} !important;
  width: 100% !important;
  &:hover {
    background: ${props => props.theme.green} !important;
    color: ${props => props.theme.offWhite} !important;
  }
`

export default PrimaryButton;
