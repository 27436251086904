import React from "react"
import styled from "styled-components"

import Typography from "@material-ui/core/Typography"

const Container = styled.div`
   padding: 32px 0 32px 0;
   color: ${props => props.color === "light" ? props=>props.theme.offWhite : props => props.theme.black};
`

const SectionHeader = ({title,color}) => {
   return (
      <Container color={color}>
         <Typography variant='h4' align='center'>
            {title.toUpperCase()}
         </Typography>
      </Container>
   )
}

export default SectionHeader;