import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'

import SectionHeader from '../SectionHeader'
import TestimonialCard from './TestimonialCard'
import SectionContainer from "../styles/HomeSectionContainer"
import bgTexture from "../../images/asfalt-light.png"

const BGContainer = styled.div`
  background-color: #2f2e2e;
  background-image: url(${bgTexture});
`

const Testimonials = () => {
   const data = useStaticQuery(graphql`
      query {
         images: allFile(filter: {relativeDirectory: {eq: "testimonials"}}) {
            edges {
            node {
               id
               childCloudinaryAsset {
                  fixed (width: 120){
                    ...CloudinaryAssetFixed
                  }
               }
            }
            }
         }
      }
   `)
   const image1 = data.images.edges[0].node.childCloudinaryAsset.fixed
   const image2 = data.images.edges[1].node.childCloudinaryAsset.fixed
   const image3 = data.images.edges[2].node.childCloudinaryAsset.fixed
   const image4 = data.images.edges[3].node.childCloudinaryAsset.fixed
   return (
     <BGContainer>
       <SectionContainer>
         <SectionHeader title="Testimonials" color="light" />
         <div
           style={{
             display: "flex",
             flexWrap: "wrap",
             justifyContent: "center",
           }}
         >
           <TestimonialCard
             person={"Aaron Carpenter"}
             subHeader={"Professional Rugby Player - Rugby Canada"}
             testimony={
               "I worked with David Thomas, when he was one of the strength and conditioning coaches at Cornish Pirates Rugby Club. I have respect for David as a knowledgeable, approachable and goal driven coach who is willing to work with the players in order to accomplish shared goals. I found that David’s background in power lifting gave him the perfect base of knowledge to guide me to improve my own lifting techniques. He also has the ability to push players to be stronger and fitter within the team environment."
             }
           >
             <Img fixed={image2} />
           </TestimonialCard>
           <TestimonialCard
             person={"Marcus Garratt"}
             subHeader={"Professional Rugby Player - Wasps"}
             testimony={
               "I worked with David for a year whilst playing championship rugby. I found his advice invaluable for my training and development, I recommend his coaching highly."
             }
           >
             <Img fixed={image3} />
           </TestimonialCard>
           <TestimonialCard
             person={"Stan Ahumada"}
             subHeader={"Professional Boxer"}
             testimony={
               "I worked with David to reach peak strength. I've always wanted to know how strong I could possibly get with proper training and technique. David has so much knowledge that I felt confident getting into movements that would normally scare me. I box professionally and with David guiding me through my strength & conditioning, I feel that potential is unlimited. Thank you David."
             }
           >
             <Img fixed={image1} style={{ margin: "0 auto" }} />
           </TestimonialCard>
           <TestimonialCard
             person={"Hugh O'Neill"}
             subHeader={"Professional Football Player - Edmonton Eskimos"}
             testimony={
               "I worked with David for my off-season training and found his programs were a perfect fit for my goals. He was able to work with me and adapt to my specific needs. I found David’s knowledge base exceptional and got the results I wanted from my training to go back to the regular season stronger, healthier and more confident in my body’s ability to perform. I would highly recommend David to anyone looking for a professional and approachable strength and conditioning coach."
             }
           >
             <Img fixed={image4} style={{ margin: "0 auto" }} />
           </TestimonialCard>
         </div>
       </SectionContainer>
     </BGContainer>
   )
}

export default Testimonials;
