import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'

import SectionHeader from "../SectionHeader"
import TeamCard from "./TeamCard"
import SectionContainer from "../styles/HomeSectionContainer"

import bgTexture from "../../images/brick-wall.png"

const BGContainer = styled.div`
  background-color: #2f2e2e;
  background-image: url(${bgTexture});
`
const Teams = () => {
   const data = useStaticQuery(graphql`
      query {
         images: allFile(filter: { relativeDirectory: { eq: "teams" } }) {
            edges {
               node {
                  id
                  base
                  childCloudinaryAsset {
                     fixed(width: 150) {
                        ...CloudinaryAssetFixed
                     }
                  }
               }
            }
         }
      }
   `)
   const imageArray = data.images.edges
   return (
      <BGContainer>
         <SectionContainer>
            <SectionHeader title="Teams Worked With" color="light"/>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} >
               {
                  imageArray.map((image) => {
                     return (
                     <TeamCard key={image.node.id} fileName={image.node.base} >
                        <Img fixed={image.node.childCloudinaryAsset.fixed} />
                     </TeamCard>
                     )
                  })
               }
            </div>

         </SectionContainer>
      </BGContainer>
   )
}

export default Teams;
