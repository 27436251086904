import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import PrimaryButton from "../styles/PrimaryButton"

const StyledCard = styled(Card)`
   width: ${props => props.theme.cardMaxWidth};
   background-color: ${props => props.theme.offWhite};
   margin: 25px;
   min-height: 240px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   & .MuiCardActions-root{
      padding: 16px;
   }
`
const IconContainer = styled.div`
   display: flex;
   justify-content: center;
   margin-bottom: 0.45em;
`

const ServiceCard = ({ title, description, route, linkDescription, children, buttonText }) => {
   return (
     <StyledCard>
       <CardContent>
          <IconContainer>
             {children}
          </IconContainer>
         <Typography gutterBottom variant="h5" component="h2" align="center">
           {title}
         </Typography>
         <Typography variant="body1" color="textPrimary" component="p" align='center' gutterBottom>
           {description}
         </Typography>
       </CardContent>
       <CardActions>
          <Link to={route} style={{textDecoration:`none`, width: `100%`}} title={linkDescription}>
              {/* <StyledOutlineBtn variant="outlined">{buttonText}</StyledOutlineBtn> */}
              <PrimaryButton variant='contained' size='large'>{buttonText}</PrimaryButton>
          </Link>
       </CardActions>
     </StyledCard>
   )
}

export default ServiceCard;
