import React from "react"
import { graphql, useStaticQuery} from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import SectionContainer from "../styles/HomeSectionContainer"
import bgTexture from "../../images/concrete-wall-2.png"
import SectionHeader from "../SectionHeader"
import ServiceCard from "./ServicesCard"


const BGContainer = styled.div`
  background-color: ${props => props.theme.grey};
  background-image: url(${bgTexture});
`

const Services = () => {
   const data = useStaticQuery(graphql`
      query {
         image1: file(relativePath: { eq: "007-sports-and-competition-1.png" }) {
            childCloudinaryAsset {
               fixed (width: 128) {
                  ...CloudinaryAssetFixed
               }
            }
         }
         image2: file(relativePath: { eq: "clipboard-icon.png" }) {
            childCloudinaryAsset {
               fixed (width: 128){
                  ...CloudinaryAssetFixed
               }
            }
         }
         image3: file(relativePath: { eq: "call.png" }) {
            childCloudinaryAsset {
               fixed (width: 128){
                  ...CloudinaryAssetFixed
               }
            }
         }
      }
   `)
   return (
     <BGContainer>
        <SectionContainer>
            <SectionHeader title="Services" color="light" />
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} >
               <ServiceCard
                  title={"Templates"}
                  description={"Set templates for specific goals"}
                  route={"/store/#templates"}
                  linkDescription="store-templates"
                  buttonText={"See Templates"}
               >
                  <Img fixed={data.image2.childCloudinaryAsset.fixed} />
               </ServiceCard>
               <ServiceCard
                  title={"Coaching"}
                  description={"One to one and online coaching"}
                  route={"/store/#coaching"}
                  linkDescription="store-coaching"
                  buttonText={"Coaching Options"}
               >
                  <Img fixed={data.image1.childCloudinaryAsset.fixed} />
               </ServiceCard>
               <ServiceCard
                  title={"Consultation"}
                  description={
                     "Skype or phone consultation for all training needs or questions"
                  }
                  route={"/store/#consultation"}
                  linkDescription="store-coaching"
                  buttonText={"Consult Options"}
               >
                  <Img fixed={data.image3.childCloudinaryAsset.fixed} />
               </ServiceCard>
            </div>
        </SectionContainer>
     </BGContainer>
   )
}




export default Services;
