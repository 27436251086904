import React from "react";
import styled from "styled-components"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

import ImageContainer from "../styles/ImageContainer"

const StyledCard = styled(Card)`
  max-width: ${props => props.theme.cardMaxWidth};
  background-color: ${props => props.theme.offWhite};
  margin: 25px;
`
const TestimonialCard = ({ person, subHeader, testimony, children }) => {
   return (
      <StyledCard>
         <CardContent>
            <Typography gutterBottom variant="h5" component="h2" align='center'>
               {person}
            </Typography>
            <ImageContainer>
               {children}
            </ImageContainer>
            <Typography gutterBottom variant="h6" align='center'>
               {subHeader}
            </Typography>
            <Typography variant="body1" color="textPrimary" component="p">
               {testimony}
            </Typography>
         </CardContent>
      </StyledCard>
  )
}

export default TestimonialCard;